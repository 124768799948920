import CryptoJS from "crypto-js";
const secretKey = process.env.REACT_APP_CODE_KEY;
// Encrypt
export const encodeHashData = (name, data) => {
  try {
    let encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      secretKey
    ).toString();
    sessionStorage.setItem(name, encryptedData);
  } catch (error) {
    if (error.name === "QuotaExceededError") {
      console.error("SessionStorage quota exceeded");
      // Optionally, you can implement a fallback mechanism here
      // For example, you can use localStorage or IndexedDB
    } else {
      console.error("Encryption error:", error);
      throw error;
    }
  }
};

// Decrypt
export const decodeHashData = (name) => {
  try {
    const encryptedData = sessionStorage.getItem(name);
    if (encryptedData) {
      let bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      let originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return originalText;
    } else return;
  } catch (error) {
    console.error("Decryption error:", error);
    throw error;
  }
};
