import { Button, styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DocViewer from "../../components/DocViewer";
import { fetchConsentDetails } from "../../components/ConsentList/consentList.slice";
import { useDispatch } from "react-redux";
import { convertDateFormat } from "../../utils/utils";
import PatientDetailsHeader from "../../components/PatientDetailsHeader";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useLocation } from "react-router-dom";
import { decodeHashData, encodeHashData } from "../../utils/hashing";
import CustomLoader from "../../components/CustomLoader";

const ConsentDocsContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(8, 6),
}));

const ConsentDetailsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing(4),
  backgroundColor: theme.palette.primaryWhite,
  padding: theme.spacing(4.5, 6),
  marginBottom: theme.spacing(8),
}));

const ConsentHeader = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

const ConsentLabel = styled("div")(({ theme }) => ({
  "&": theme.typography.customKeys,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));
const ConsentValue = styled("div")(({ theme }) => ({
  "&": theme.typography.p,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

const ConsentDocumentPage = () => {
  const location = useLocation();
  const { consentData } = location.state || {};
  const [FhirDocDetails, setFhirDocDetails] = useState({});
  const [showLoader, setShowLoader] = useState(false);

  const consentSelected = decodeHashData("consentSelected");
  const selectedPatient = decodeHashData("selectedPatient");
  const [documentData, setDocumentData] = useState([]);
  const dispatch = useDispatch();

  const handleConsentDetails = () => {
    if (consentSelected && Object.keys(consentSelected)?.length) {
      setShowLoader(true);
      const consentId = consentSelected?.id;
      dispatch(fetchConsentDetails(consentId)).then((response) => {
        setShowLoader(false);
        const consentData = response?.payload;
        const formattedConsentList = {
          consultationDate: new Date(
            consentData?.patient_data_transformed
          ).toLocaleDateString("en-GB", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }),
          hipId: consentData?.hip_id,
          patientDataTransformed: consentData?.patient_data_transformed,
        };
        console.log("formattedConsentList", formattedConsentList);
        createDocumentData(formattedConsentList);
        sessionStorage.removeItem("FhirDocDetails");
        setFhirDocDetails(formattedConsentList);
        // console.log("consentData", formattedConsentList);
        // encodeHashData("FhirDocDetails", consentData?.patient_data_transformed);
      });
    }
  };

  const createDocumentData = (data) => {
    const doclist = [];
    const pname = { patient_name: selectedPatient?.name };
    doclist.push(pname);
    const contexts = data?.patientDataTransformed;
    if (contexts && typeof contexts === "object") {
      Object?.entries(contexts)?.forEach(([key]) => {
        const docObj = {
          careContext: key,
          date: convertDateFormat(
            data?.patientDataTransformed?.[key]?.Encounter?.[0]?.period
              ?.start ||
              data?.patientDataTransformed?.[key]?.Composition?.[0]?.date,
            "dd-MM-yyyy"
          ),
          hipName: data?.patientDataTransformed?.[key]?.Organization?.[0]?.name,
          patientDataTransformed: data?.patientDataTransformed?.[key],
        };
        doclist.push(docObj);
      });
    }
    setDocumentData(doclist);
  };

  useEffect(() => {
    handleConsentDetails();
  }, []);

  const details = [
    {
      key: "consentStatus",
      label: "Request Status",
      value: consentSelected?.status,
    },
    {
      key: "consentCreatedOn",
      label: "Consent Created On",
      value: convertDateFormat(consentSelected?.created_at, "dd-MM-yyyy"),
    },
    {
      key: "consentExpiryOn",
      label: "Consent Expiry On",
      value: convertDateFormat(consentSelected?.expire_at, "dd-MM-yyyy"),
    },
  ];

  return (
    <>
      <CustomLoader open={showLoader} />
      <ConsentDocsContainer>
        <PatientDetailsHeader />
        <ConsentDetailsWrapper>
          {details?.map((item) => (
            <ConsentHeader key={item.key}>
              <ConsentLabel>{item.label}</ConsentLabel>
              <ConsentValue>{item.value}</ConsentValue>
            </ConsentHeader>
          ))}
          <Button
            variant="outlined"
            sx={{ textTransform: "none" }}
            onClick={() => handleConsentDetails()}
          >
            <RefreshIcon /> <Typography ml={2}>Refresh</Typography>
          </Button>
        </ConsentDetailsWrapper>
        <DocViewer docData={documentData} />
      </ConsentDocsContainer>
    </>
  );
};

export default ConsentDocumentPage;
